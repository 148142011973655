import React from 'react'
import { Helmet } from "react-helmet"
//import './layout.css'
import 'normalize.css'
import GlobalStyles from '../styles/GlobalStyles'
import Typography from '../styles/Typography'
import ImageStyles from '../styles/ImageStyles'
import Nav from './Nav'
import Footer from './Footer'
import Favicon from "../images/Favicon.png"

export default function Layout({ children }) {
  return (
    <>
      <Helmet defer={false} link={[{ rel: "icon", type: "image/png", href: Favicon }]} />
      <GlobalStyles />
      <Typography />
      <ImageStyles />
      <Nav />
      <main>{children}</main>
      <Footer />
    </>
  )
}