import { createGlobalStyle } from 'styled-components';

const ImageStyles = createGlobalStyle`

  .mask-circle {

    img {
      display: block;
      width: 100%;
      height: auto;
      object-fit: cover;

      mask-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTk1IiBoZWlnaHQ9IjU5NSIgdmlld0JveD0iMCAwIDU5NSA1OTUiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxjaXJjbGUgY3g9IjI5Ny42NzciIGN5PSIyOTcuNTE2IiByPSIyOTYuNzc0IiBmaWxsPSIjRkYwMDAwIi8+Cjwvc3ZnPgo=);
      mask-repeat: no-repeat;
      mask-size: 70%;
      mask-origin: border-box;
      mask-position: center;
      width: 100%;
    }
  }

  .klint-photo-dark {

    @media screen and (min-width: 1025px) {
      width: 100%;
      height: auto;
      transform: scale(0.8);
      transform-origin: 43% 49%;
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg) scale(1);
    }
    to {
      transform: rotate(360deg) scale(1);
    }
  }
`;

export default ImageStyles;