import { createGlobalStyle } from 'styled-components';

const Typography = createGlobalStyle`

  html, body {
    font-family: var(--fontPrimary);
    color: var(--outerspace);
    font-weight: 300;
  }

  p, li {
    letter-spacing: 0.5px;
  }

  h1,h2,h3,h4,h5,h6 {
    font-family: var(--fontSecondary);
    font-weight: normal;
    margin: 0;
    z-index: 1;
  }

  h3 {
    font-size: 2.5rem;
  }

  .highlight {
    color: var(--coral);
  }

  a {
    color: var(--black);
    text-decoration-color: var(--outerspace);
    /* Chrome renders this weird with this font, so we turn it off */
    text-decoration-skip-ink: none;
  }

  section {
    p {
      font-weight: 300;
      line-height: 1.75em;
      padding-bottom: 1em;
      letter-spacing: -0.001em;

      strong {
        font-weight: 600;
      }
    }

    .bigly {
      font-size: 6em;
      font-family: var(--fontSecondary);
      letter-spacing: -0.05em;
    }

    
  }  

  .hugely {
    font-family: var(--fontPrimary);
    color: var(--atmospheric);
    font-size: clamp(20em, 10vw, 26em);
    width: 100%;
    word-wrap: break-word;
    line-height: 0.8em;
    text-transform: uppercase;
    font-weight: 700;
  }

  .center {
    text-align: center;
  }

`;

export default Typography;
