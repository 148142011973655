import React from 'react'
import { Link } from "gatsby"
import styled from 'styled-components'
import Logo from './Logo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

const NavStyles = styled.nav`
  background: transparent;
  height: auto;
  width: calc(100% - 2rem);
  margin: 1rem;
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: 1fr;
  gap: 0.5rem 0;
  position: relative;

  @media screen and (min-width: 727px) { // tablet
    width: calc(100% - 4rem);
    margin-left: auto;
    margin-right: auto;
    grid-template-rows: 1fr;
    grid-template-columns: 40% 20% 40%;
    align-items: center;
  }

  @media screen and (min-width: 1025px) { // laptop
    grid-template-columns: repeat(12, 1fr);
  }

  svg[data-icon="bars"] {
    //grid-area: 1 / 1 / 5 / 2;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 2rem;
    display: none;
  }

  .logo {
    grid-area: 1 / 1 / 2 / 3;
    text-align: center;

    @media screen and (min-width: 727px) { // tablet
      grid-area: 1 / 2 / 2 / 3;
    }

    @media screen and (min-width: 1025px) { // laptop
      grid-area: 1 / 1 / 2 / 2;
    }
    
    svg {
      width: auto;
      height: 40px;

      @media screen and (min-width: 727px) { // tablet
        height: 72px;
      }
    }
  }

  #nametag {
    grid-area: 2 / 1 / 2 / 3;

    @media screen and (min-width: 727px) { // tablet
      grid-area: 1 / 3 / 2 / 4;
    }

    @media screen and (min-width: 1025px) { // laptop
      grid-area: 1 / 2 / 2 / 5;
    }

    .name,
    .tag {
      display: block;
      text-align: center;

      @media screen and (min-width: 727px) { // tablet
        text-align: right;
      }

      @media screen and (min-width: 1025px) { // laptop
        text-align: left;
      }
    }

    .name {
      font-size: 1.5rem;
      font-family: var(--fontSecondary);

      @media screen and (min-width: 727px) { // tablet
        font-size: 2em;
      }
    }

    .tag {
      font-size: 14px;
    }
  }

  ul {
    display: none;

    @media screen and (min-width: 1025px) { // laptop
      display: block;
      grid-area: 1 / 11 / 2 / 13;
    }

    a {
      color: var(--coral);
      font-weight: 600;
      text-decoration: none;
      position: relative;

      &:after {
        content: '';
        background-color: var(--coral);
        height: 8px;
        width: 80%;
        left: 10%;
        bottom: -0.75em;
        position: absolute;
        border-radius: 99em;
      }
    }
  }
`

export default function Nav() {
  return (
    <NavStyles>
      <FontAwesomeIcon icon={faBars} size="1x" />
      <Logo className="logo"></Logo>
      <div id="nametag">
        <span className="name">Klint Holland</span>
        <span className="tag">Builder and designer of products and teams.</span>
      </div>
      <ul>
        <Link to="/">Hello.</Link>
      </ul>
    </NavStyles>
  )
}