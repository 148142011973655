import React from 'react';

export default function Logo(props) {
  return (
    <div className={props.className}>
      <svg width="40" height="52" viewBox="0 0 40 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
        <path id="Vector 4" d="M14.2657 49.5195H23.9455" stroke="#282D30" strokeWidth="4.88831" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector 7" d="M9.66772 12.3008H28.9306" stroke="#F87D55" strokeWidth="4.88831" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector 8" d="M9.66772 2.57324H28.9306" stroke="#F87D55" strokeWidth="4.88831" strokeLinecap="round" strokeLinejoin="round"/>
        <g id="Component 1">
        <path id="Vector 9" d="M19.517 40.083L19.275 40.083C9.91943 40.083 2.33528 32.4988 2.33528 23.1433L2.33528 19.3198" stroke="#F87D55" strokeWidth="4.64632" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector 10" d="M2.3353 19.3193L2.5773 19.3193C11.9328 19.3193 19.517 26.9035 19.517 36.259L19.517 40.0826" stroke="#F87D55" strokeWidth="4.64632" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <g id="Component 2">
        <path id="Vector 9_2" d="M19.5411 40.083L19.7831 40.083C29.1387 40.083 36.7228 32.4988 36.7228 23.1433L36.7228 19.3198" stroke="#F87D55" strokeWidth="4.64632" strokeLinecap="round" strokeLinejoin="round"/>
        <path id="Vector 10_2" d="M36.7228 19.3193L36.4808 19.3193C27.1253 19.3193 19.5411 26.9035 19.5411 36.259L19.5411 40.0826" stroke="#F87D55" strokeWidth="4.64632" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        </g>
      </svg>
    </div>
  )
}