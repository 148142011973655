import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --cotton: #FFFAF8;
    --paper: #FCF5F1;
    --atmospheric: #4A5360;
    --outerspace: #2F3A42;
    --deepspace: #232A30;
    --coral: #F87D55;
    --cotta: #FF9C64;
    --tacao: #EFAF8A;
    --fontPrimary: 'Noto Sans', serif;
    --fontSecondary: 'PT Serif', sans-serif;
    --breakTablet: '727px';
  }

  html {
    background-color: var(--paper);
    background-size: 450px;
    background-attachment: fixed;
    font-size: 16px;
  }

  body {
    font-size: 1rem;
    font-weight: 300;
    color: var(--atmospheric);
  }

  main {
    position: relative;
    z-index: 10;
  }

  footer {
    position: relative;
    z-index: 20;
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  .container {
    --paddingLeft: 2em;
    --paddingRight: 2em;

    width: calc(100% - 4rem);
    max-width: 100%;
    margin: 0 auto;
    padding-left: var(--paddingLeft);
    padding-right: var(--paddingRight);

    @media screen and (min-width: 1025px) {
      width: 1180px;
      --paddingLeft: 4em;
      --paddingRight: 4em;
    }
  }

  button,
  .button {
    --cast: 4px;
    background: var(--deepspace);
    color: var(--paper);
    border: 4px solid var(--coral);
    padding: 1em 2.5em;
    border-radius: 99em;
    cursor: pointer;
    box-shadow: 0 var(--cast) 16px -8px var(--coral);
    text-shadow: 0.5px 0.5px 0 rgba(0,0,0,0.2);
    transition: all 0.3s;
    display: inline-block;
    margin: 0 0 1em;
    
    &:hover {
      --cast: 8px;
      border-color: var(--cotta);
      background: var(--outerspace);
    }
  }

  /*.gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }*/

  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }

  html {
    scrollbar-width: thin;
    scrollbar-color: var(--paper) var(--atmospheric);
  }

  body::-webkit-scrollbar-track {
    background: var(--paper);
  }

  body::-webkit-scrollbar-thumb {
    background-color: var(--atmospheric) ;
    border-radius: 6px;
    border: 3px solid var(--paper);
  }

  hr {
    border: 0;
    height: 8px;
    background-size: 1500px;
  }

  img {
    max-width: 100%;
  }

  .tilt {
    transform: rotate(-2deg);
    position: relative;
    display: inline-block;
  }

  .break {
    display: block;
  }

  .breakMobile {
    display: block;

    @media screen and (min-width: 727px) {
      display: inline;
    }
  }

  .coral {
    color: var(--coral);
  }

  .atmospheric {
    color: var(--atmospheric);
  }

`;

export default GlobalStyles;
