import React from 'react'
import styled from 'styled-components'
import backgroundImage from '../images/bg_kii__wavePaper.svg'
import Logo from '../images/logo_kii__inverted-cotta.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

const FooterStyles = styled.footer`
  background: url(${backgroundImage}) no-repeat top left;
  background-size: 100%;
  width: 100%;
  margin-top: -150px;
  text-align: center;
  padding: 50px 0;

  .container {

    width: 85%;
    margin: 0 auto;

    @media screen and (min-width: 727px) { // tablet
      width: 40%;
    }
  }

  .quoteLine {
    font-family: var(--fontSecondary);
    font-size: 1.65rem;
    color: var(--coral);
    line-height: 1.3em;
  }

  img {
    display: block;
    margin: 0 auto 2rem;
    width: 32px;
    height: auto;
  }

  hr {
    border: none;
    background-color: var(--coral);
    height: 8px;
    border-radius: 99rem;

    @media screen and (min-width: 727px) { // tablet
      width: 100%;
    }
  }

  .social {
    svg {
      width: 1.25rem;
      height: auto;
      margin: 2rem 0.75rem;
      color: var(--atmospheric);
    }
  }
`;

export default function Footer() {
  return (
    <FooterStyles>
      <div className="container">
        <img src={Logo} alt="Klint Holland's Logomark"/>
        <span className="quoteLine">
        “An artist is a mathematician who knows <span className="atmospheric">the formulas of the soul.</span>”
        </span>
        <div className="social">
          <a href="https://www.linkedin.com/in/klint-holland/" target="_blank" rel="noreferrer" aria-label="View Klint's LinkedIn Page"><FontAwesomeIcon icon={faLinkedin} /></a>
          <a href="https://forms.gle/SP7CZBXELK5C5SxNA" target="_blank" rel="noreferrer" aria-label="Link to email Klint"><FontAwesomeIcon icon={faEnvelope} /></a>
        </div>
        <hr />
      </div>
    </FooterStyles>
  )
}